<template>
  <div
    class="info container van-tab__panel"
    :style="{ backgroundImage: 'url(img/pro-bg.png)' }"
  >
    <div v-if="lang==='cn'" class="con">
      <p>产品介绍</p>
      <p>
        结果来源于官方数据，每轮开出20个号码，并按照从小到大的循序以此排列。
      </p>
      <p>取1-6位号码并进行相加，和值的末尾数作为每轮结果的第一位数值;</p>
      <p>取7-12位号码并进行相加，和值的末尾数作为每轮结果的第二位数值;</p>
      <p>取13-18位号码并进行相加，和值的末尾数作为每轮结果的第三位数值;</p>
      <p>将三位数值相加，所得结果即为每轮的和值结果;</p>
      <p>产品类型可分为以下类型:</p>
      <p><br /></p>
      <p>一、常规交易</p>
      <p>
        1.做多:三个号码和值大于等于14, 15，16，17, 18，19，20, 21, 22，23, 24,
        25, 26, 27
      </p>
      <p>
        2.做空:三个号码和值小于等于00，01, 02， 03, 04, 05，06，07,
        08，09，10，11，12, 13
      </p>
      <p>3.平单:三个号码和值尾数为奇数</p>
      <p>4.平双:三个号码和值尾数为偶数</p>
      <p>5.多单:三个号码和值为15, 17, 19, 21, 23, 25, 27</p>
      <p>6:多双:三个号码和值为 14，16，18，20，22，24，26</p>
      <p>7:空单:三个号码和值为 01，03，05，07，09，11，13</p>
      <p>8:空双:三个号码和值为 00，02，04，06，08，10，12</p>
      <p><br /></p>
      <p>本平台禁止恶意交易行为，一旦发现禁封账号。</p>
    </div>
       <div v-else class="con">
      <p>Product description</p>
      <p>The results are derived from official data. 20 numbers are drawn in each round, and they are arranged in order from small to large. </p>
      <p>Take 1-6 digits and add them together, and the last digit of the sum value is used as the first value of the result of each round;</p>
      <p>Take the 7-12 digits and add them together, and the last digit of the sum is used as the second digit of the result of each round;</p>
      <p>Take 13-18 digits and add them together, and the last digit of the sum is used as the third digit of the result of each round;</p>
      <p>Add the three values, and the result is the sum of each round;</p>
      <p>Product types can be divided into the following types:</p>
      <p><br /></p>
      <p>Regular transactions</p>
      <p>

1. Go long: three numbers and values ​​greater than or equal to 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27<br />
<br />
2. Short: three numbers and values ​​less than or equal to 00, 01, 02, 03, 04, 05, 06, 07, 08, 09, 10, 11, 12, 13
<br />
3. Flat order: three numbers and values ​​with odd mantissa
<br />
4. Ping and double: the mantissa of the three numbers and values ​​is an even number
<br />
5. Long order: three numbers and values ​​are 15, 17, 19, 21, 23, 25, 27
<br />
6: Multi-Double: Three numbers and values ​​are 14, 16, 18, 20, 22, 24, 26
<br />
7: Empty order: three numbers and values ​​are 01, 03, 05, 07, 09, 11, 13
<br />
8: Empty Double: Three numbers and values ​​00, 02, 04, 06, 08, 10, 12
<br /><br />
This platform prohibits malicious transactions, and once found, the account will be banned.
</p>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const lang=ref(localStorage.getItem('lang'));
</script>
<style lang="less" scoped>
.info {
  background-repeat: no-repeat;
  background-size: 80% auto;
  background-position: 0 -50px;
  color: #ddd;
  min-height: 300px;
}
</style>
